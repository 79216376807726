import React from "react"
import Button from "../Button/Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import "./hero.scss"
function Hero(props) {
  const hero = props?.hero

  return (
    <div className="hero-banner">
      <div className="image-wrapper">
        <GatsbyImage
          image={getImage(hero?.heroImage?.localFile)}
          className="w-full h-full"
          objectFit="cover"
          objectPosition="left top"
        />
      </div>
      <div className="hero-content">
        <div className="title">
          <h1 className="main-title">{hero?.title}</h1>
        </div>
        <div className="buttons">
          {hero?.buttons?.map(({ button }, index, origin) => {
            return (
              <Button
                key={button?.title}
                button={{
                  text: button?.title,
                  url: button?.url,
                  target: button?.target,
                  type: index === origin.length - 1 ? "secondary" : "primary",
                }}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Hero
