import React, { useState, useEffect } from "react"
import "./standardText.scss"
import parse from "html-react-parser"
import CallbackForm from "../CallbackForm/CallbackForm"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Image from "../../helper/Image"

function StandardText(props) {
  const [open, setOpen] = useState(false)
  const [callbackShape, setCallbackShape] = useState()
  const standardText = props?.standardText
  const mediaQuery = useStaticQuery(graphql`
    query {
      media: allWpMediaItem(filter: { mediaType: { eq: "image" } }) {
        edges {
          node {
            id
            title
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 70, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)
  const media = mediaQuery?.media?.edges
  let Content
  if (standardText?.mainText) {
    Content = parse(standardText?.mainText, {
      replace: domNode => {
        if (domNode.name === "img") {
          const src = decodeURIComponent(
            domNode?.attribs?.src?.split("?u=")[1]?.split("&a=")[0]
          )
          let srcUrl = src
          if (src.includes("http:")) {
            srcUrl = src?.replace("http:", "https:")
          }

          let image = media?.filter(m => {
            return m?.node?.sourceUrl === srcUrl
          })
          if (image.length !== 0) {
            return (
              <div
                className={
                  "gatsby-img " +
                  `${domNode?.attribs?.class ? domNode?.attribs?.class : ""}`
                }
              >
                <Image image={image[0].node} alt={image[0].node.title} />
              </div>
            )
          }
        }
      },
    })
  }
  useEffect(() => {
    setCallbackShape(standardText?.customCallBackButton?.buttonShape)
  }, [standardText?.customCallBackButton?.buttonShape])

  return (
    <div
      className={`standard-text ${
        standardText?.backgroundColor || standardText?.backgroundImage
          ? "light-text"
          : ""
      }`}
      style={{
        backgroundColor: standardText?.backgroundColor,
        backgroundImage: standardText?.backgroundImage
          ? `url(${standardText?.backgroundImage?.sourceUrl})`
          : "",
      }}
    >
      <div className="container">
        <div className="main-content-standard-text ">
          {standardText?.mainText && (
            <div
              className={
                "rte-standard-text " +
                `${callbackShape === "Circle" ? "circle" : ""}`
              }
            >
              {Content}
            </div>
          )}
          {standardText?.customCallBackButton?.showButton &&
            (standardText?.customCallBackButton?.buttonShape === "Circle" ? (
              <div
                onClick={() => {
                  setOpen(!open)
                }}
                className="circle-call-back"
                style={{
                  backgroundColor: `${standardText?.customCallBackButton?.buttonColor}`,
                  border: `solid 2px ${standardText?.customCallBackButton?.buttonBorderColor}`,
                }}
              >
                <p
                  style={{
                    color: `${standardText?.customCallBackButton?.buttonTextColor}`,
                  }}
                >
                  {standardText?.customCallBackButton?.buttonText}
                </p>
              </div>
            ) : (
              <div
                onClick={() => {
                  setOpen(!open)
                }}
                className="square-call-back btn"
              >
                <a
                  href="#"
                  style={{
                    backgroundColor: `${standardText?.customCallBackButton?.buttonColor}`,
                    color: `${standardText?.customCallBackButton?.buttonTextColor}`,
                    border: `solid 2px ${standardText?.customCallBackButton?.buttonBorderColor}`,
                  }}
                >
                  {standardText?.customCallBackButton?.buttonText}
                </a>
              </div>
            ))}
          {standardText?.customButton && (
            <div className="custom-buttons">
              {standardText?.customButton?.map(button => {
                return (
                  <div className="btn custom-btn" key={button?.button?.title}>
                    <Link
                      to={button?.button?.url}
                      target={button?.button?.target}
                      style={{
                        backgroundColor: `${button?.buttonColor}`,
                        color: `${button?.buttonTextColor}`,
                      }}
                    >
                      {button?.button?.title}
                    </Link>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>

      {standardText?.customCallBackButton && (
        <CallbackForm open={open} setOpen={setOpen} />
      )}
    </div>
  )
}

export default StandardText
