import loadable from "@loadable/component"

export { default as hero } from "../components/Hero/Hero"
// export { default as internalhero } from "../components/Hero/InternalHero"
const internalhero = loadable(() =>
  import(
    /* webpackChunkName: "internalhero" */ "../components/Hero/InternalHero"
  )
)
const faq = loadable(() => import("../components/Faq/Faq"))
// const standardtext = loadable(() =>
//   import(
//     /* webpackChunkName: "standardtext" */ "../components/StandardText/StandardText"
//   )
// )

export { default as standardtext } from "../components/StandardText/StandardText"

const sectorslider = loadable(() =>
  import(
    /* webpackChunkName: "sectorslider" */ "../components/SectorSlider/SectorSlider"
  )
)
const fullwidthimagecard = loadable(() =>
  import("../components/FullWidthImageCard/FullWidthImageCard")
)
const thewirehouseservices = loadable(() =>
  import("../components/WirehouseServices/WirehouseServices")
)
const financialdamagetext = loadable(() =>
  import("../components/FinancialDamageText/FinancialDamageText")
)
const sectorbanner = loadable(() =>
  import("../components/SectorBanner/SectorBanner")
)
const ctabutton = loadable(() => import("../components/CTAButton/CTAButton"))
const latestpost = loadable(() =>
  import(
    /* webpackChunkName: "latestpost" */ "../components/LatestPost/LatestPost"
  )
)
const consultationform = loadable(() =>
  import("../components/ConsultationForm/ConsultationForm")
)
const testimonial = loadable(() =>
  import("../components/Testimonial/Testimonial")
)
// const ctastrip = loadable(() => import("../components/CTAStrip/CTAStrip"))
export { default as ctastrip } from "../components/CTAStrip/CTAStrip"
const ourservicesrepeater = loadable(() =>
  import("../components/OurServices/OurServices")
)
const imagecardrepeater = loadable(() =>
  import("../components/ImageCardRepeater/ImageCardRepeater")
)
const clientreview = loadable(() =>
  import("../components/ClientReview/ClientReview")
)
const sectornavigation = loadable(() =>
  import("../components/SectorNav/SectorNav")
)
const sectorcontent = loadable(() =>
  import("../components/SectorContent/SectorContent")
)
const map = loadable(() => import("../components/Map/Map"))
const selectcpt = loadable(() => import("../components/SelectCPT/SelectCPT"))
const fileselect = loadable(() => import("../components/FileSelect/FileSelect"))
const spacer = loadable(() => import("../components/Spacer/Spacer"))
const formpicker = loadable(() => import("../components/FormPicker/FormPicker"))

const blockMap = {
  internalhero,
  clientreview,
  consultationform,
  ctabutton,
  faq,
  fileselect,
  financialdamagetext,
  formpicker,
  fullwidthimagecard,
  imagecardrepeater,
  latestpost,
  map,
  ourservicesrepeater,
  sectorbanner,
  sectorcontent,
  sectornavigation,
  sectorslider,
  spacer,
  selectcpt,
  testimonial,
  thewirehouseservices,
}
export default blockMap
