import React, { useRef, useEffect, useContext, useState } from "react"
import "./ctaStrip.scss"
import parse from "html-react-parser"
import { ShareDataContext } from "../../context/ShareDataContext"

function CTAStrip(props) {
  const ref = useRef()
  const emptyDiv = useRef()
  const { navHeight } = useContext(ShareDataContext)
  const [sticky, setSticky] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const checkPosition = emptyDiv.current.getBoundingClientRect()
      const topPosition = checkPosition.top - 10
      if (topPosition <= navHeight) {
        setSticky(true)
      } else if (topPosition > navHeight) {
        setSticky(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [navHeight])

  return (
    <div>
      <div className="cta-check-position" ref={emptyDiv}></div>
      <div className={"cta-strip " + `${sticky ? "sticky-cta" : ""}`} ref={ref}>
        <div className="container">
          {props?.ctaStrip?.text && (
            <div className="rte-cta-strip"> {parse(props?.ctaStrip?.text)}</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CTAStrip
