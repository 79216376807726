import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import BlockReturn from "../helper/blockReturn"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { ApolloProvider } from "@apollo/client"
import { client } from "../hooks/apolloClient"
import Portal from "../components/Portal/Portal"
import GravityFormForm from "gatsby-plugin-gravity-forms"
import logo from "../images/logo.png"
import "../components/CallbackForm/callbackForm.scss"
import parser from "html-react-parser"
import GatedForm from "../components/GatedForm"

function CreatePages({ data, pageContext }) {
  const pageID = pageContext.id
  const form = { wpGfForm: data?.wpGfForm, wp: data?.wp }
  const blocks = data.page.blocks
  const { gatedForm, formTitle, formDescription } = data?.page?.gatedForm
  const [showForm, setShowForm] = useState(gatedForm)
  const [hasFilledForm, setHasFilledForm] = useState()
  // const [hasSubmittedForm, setHasSubmittedForm] = useState(false)
  useEffect(() => {
    // const storedHasFilledForm = localStorage.getItem(`${pageID}`)
    // const initialHasFilledForm = storedHasFilledForm === "true"

    // setHasFilledForm(initialHasFilledForm)

    if (gatedForm && !initialHasFilledForm) {
      setShowForm(true)
    }
  }, [gatedForm])

  const handleFormSubmit = () => {
    // localStorage.setItem(`${pageID}`, "true")
    setShowForm(false)
    // setHasFilledForm(true)
    setHasSubmittedForm(true)
  }

  const successCallback = ({ data: formRes, reset }) => {
    // This will be called after the form is successfully submitted
    handleFormSubmit()
  }

  if (blocks[0]?.name === undefined || blocks?.length === 0) {
    return (
      <Layout>
        <div
          className="container"
          style={{
            textAlign: "center",
            fontSize: "35px",
            lineHeight: "35px",
            fontWeight: "700",
            margin: "70px auto",
          }}
        >
          Page does't have any content yet. Please add content from{" "}
          <a
            style={{ color: "#53bbad" }}
            target="_blank"
            href="https://wirehouse.saigondigital.dev/wp-admin/edit.php?post_type=page"
          >
            WP back-end
          </a>
        </div>
      </Layout>
    )
  } else {
    return (
      <ApolloProvider client={client}>
        <Layout>
          {showForm ? (
            <GatedForm
              data={form}
              formDescription={formDescription}
              formTitle={formTitle}
              successCallback={successCallback}
              pageTitle={data.page.title}
            />
          ) : (
            <div className={pageContext.slug}>
              {blocks.length > 0 && <BlockReturn blocks={blocks} />}
            </div>
          )}
        </Layout>
      </ApolloProvider>
    )
  }
}
export const Head = ({ data }) => (
  <Seo
    title={data?.seo?.seo?.title}
    description={data?.seo?.seo?.metaDesc}
    image={data?.seo?.featuredImage?.node?.sourceUrl}
    props={data?.seo?.seo}
  />
)
export default CreatePages
export const query = graphql`
  query ($id: String!) {
    page: wpPage(id: { eq: $id }) {
      ...Layout
    }
    wp {
      ...GravityFormSettings
    }
    wpGfForm(databaseId: { eq: 14 }) {
      ...GravityFormFields
    }
    seo: wpPage(id: { eq: $id }) {
      featuredImage {
        node {
          sourceUrl
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          srcSet
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          srcSet
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
