import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
export default function Image({ image, alt }) {
  return (
    <GatsbyImage
      image={getImage(image?.localFile)}
      alt={alt ? alt : "Gatsby-Image"}
      fadeIn={false}
      loading="eager"
    />
  )
}
